import { match, P } from 'ts-pattern'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { ContentfulBreadcrumbs as Breadcrumbs } from '@cais-group/shared/ui/contentful/breadcrumb'
import {
  PageErrorSplash,
  Main,
} from '@cais-group/shared/ui/contentful/components'
import { DocumentTitle } from '@cais-group/shared/util/document-title'

import { useGetLegalPageBySlug } from '../../domain/contentful/legal/useGetLegalPageBySlug'
import { APP_URL } from '../../utils/common/constants'

import { DisclosurePage } from './disclosure'

export const DISCLOSURE_PAGE_SLUG = 'disclosure'

export function DisclosurePageContainer() {
  const { pageData, error, loading } = useGetLegalPageBySlug({
    slug: DISCLOSURE_PAGE_SLUG,
  })
  return (
    <>
      <Breadcrumbs
        baseUrl={APP_URL}
        category=""
        currentPage="Disclosure"
        levelOneOnly
      />

      <Main fullWidth>
        {match({ error, pageData, loading })
          .with({ error: true }, () => <PageErrorSplash.General />)
          .with({ loading: true, error: false }, () => (
            <LoadingContainer
              state={LoadingState.LOADING}
              type="large"
              coverPage="FULL_SCREEN_WITH_HEADER"
            />
          ))
          .with(
            { error: false, pageData: P.not(P.nullish) },
            ({ pageData }) => (
              <>
                <DocumentTitle title="Disclosure" />
                <DisclosurePage data={pageData} />
              </>
            )
          )
          .otherwise(() => null)}
      </Main>
    </>
  )
}
