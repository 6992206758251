import { Fragment } from 'react'

import { type UserProfileV1 } from '@cais-group/access-manager/domain/api'
import { ButtonLink } from '@cais-group/equity/atoms/button'
import { StatusTag } from '@cais-group/equity/atoms/status-tag'
import { mapTrackingKeysToDataAttributes } from '@cais-group/equity/util/tracking-utils'
import {
  getEntitledNavLinks,
  getUserEnabledCapabilities,
  getUserEnabledFeatures,
  type NavItem,
} from '@cais-group/shared/util/entitled-nav-links'

import { trackingLabels } from '../../utils/tracking/map-url-to-section/homepage'

type QuickLinksProps = {
  links?: NavItem[]
  userProfile: UserProfileV1 | null
}

export function QuickLinks(props: QuickLinksProps) {
  const { links, userProfile } = props

  if (!links) {
    return null
  }

  const enabledAccessControls = {
    enabledCapabilities: getUserEnabledCapabilities(userProfile?.capabilities),
    enabledFeatures: getUserEnabledFeatures(userProfile?.features),
    enabledPermissions: userProfile?.permissions || [],
    enabledPersonas: userProfile?.personas || [],
  }

  const entitledQuickLinks = getEntitledNavLinks(links, enabledAccessControls)

  const hasLinks =
    entitledQuickLinks.length > 0 &&
    'menu' in entitledQuickLinks[0] &&
    entitledQuickLinks[0]?.menu.groups.length > 0

  return hasLinks ? (
    <div className="@container bg-neutral-0 p-24">
      {entitledQuickLinks.map((entry) => {
        return (
          <Fragment key={entry.name}>
            <h3 className="headline-s-strong mb-24">{entry.name}</h3>
            <div
              className="@[480px]:flex-row flex flex-col flex-wrap gap-16 lg:gap-24"
              {...mapTrackingKeysToDataAttributes({
                sub_section: trackingLabels.subSection.QuickLinks,
              })}
            >
              {'menu' in entry
                ? entry.menu.groups.map((group, index) => (
                    <div key={index} className="flex-grow">
                      <p className="small-strong text-neutral-600">
                        {group.name}
                      </p>
                      <ul className="mb-24 mt-8 last:mb-0">
                        {group.items.map((item) => (
                          <li
                            key={item.href}
                            className="flex items-center gap-4 *:h-auto *:py-8 xl:gap-x-8"
                          >
                            <ButtonLink
                              variant="tertiary"
                              href={item.href}
                              openInNewWindow={false}
                              external
                              startAdornment={item.icon}
                              tracking={{
                                item_name: `${group.name}: ${item.name}`,
                              }}
                            >
                              <span className="sr-only">{group.name}: </span>
                              {item.name}
                            </ButtonLink>
                            {item?.status ? (
                              <span className="!p-0 uppercase">
                                <StatusTag color="success" variant="light">
                                  {item.status}
                                </StatusTag>
                              </span>
                            ) : null}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))
                : null}
            </div>
          </Fragment>
        )
      })}
    </div>
  ) : null
}

export const Shortcuts = QuickLinks
