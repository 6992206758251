import { datadogLogs } from '@datadog/browser-logs'
import * as React from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  AcceptTermsAndConditionsInputV1,
  UserProfileV1,
  acceptTermsAndConditionsV1,
  getLoggedInUserProfileV1,
  type UserTermsAndConditionsV1,
} from '../../../../../tmp/generated/access-manager-api'
import { getEnvConfig } from '../../../../ui/env'
import { Feature } from '../../../entitled-nav-links'

export type UserProfileState = {
  userProfile: UserProfileV1 | null
  status: 'loading' | 'loaded' | 'error' | 'notAsked'
  // It would be nice to decouple however accepting terms is something that will typically/should reevaluate the user profile
  acceptingTermsStatus: 'not' | 'accepting' | 'accepted' | 'error'
  error: Error | null
  acceptingError: Error | null
}

export type UserProfileValue = {
  userProfileState: UserProfileState
  acceptTerms: (params: AcceptTermsAndConditionsInputV1) => Promise<void>
  refetch: () => Promise<void>
}

// KCD approach to defaults for context: https://kentcdodds.com/blog/how-to-use-react-context-effectively#typescript
export const UserProfileContext = createContext<UserProfileValue | undefined>(
  undefined
)

export function useUserProfile() {
  const context = useContext(UserProfileContext)
  if (context === undefined) {
    throw new Error('useUserProfile must be used within a UserProfileProvider')
  }
  return context
}

// TODO: see 39502, would be good to figure out something like this to keep things simpler in cases where know will be loaded:
// export function useUserProfileSynchronously() {
//   const {
//     userProfileState: { userProfile },
//   } = useUserProfile()
//   if (!userProfile) {
//     throw new Error(
//       'useUserProfileSynchronously assumes user profile is loaded and should only be used in contexts where this will always be valid'
//     )
//   }
//   return userProfile
// }

export async function fetchUserProfile(
  apiUrl?: string
): Promise<UserProfileV1> {
  const userProfile = await getLoggedInUserProfileV1()

  return userProfile
}

async function acceptTerms(
  params: AcceptTermsAndConditionsInputV1
): Promise<UserTermsAndConditionsV1> {
  return acceptTermsAndConditionsV1(params)
}

export function UserProfileProvider({
  children,
  user,
}: {
  children: React.ReactNode
  user?: UserProfileV1
}) {
  // Could possibly use react-query here for more standardised API, but this a little nicer with context, possibly we do want to handle errors, caching etc in special ways as so fundamental; also combining more than one async thing

  const [userProfile, setUserProfile] = useState<UserProfileState>({
    userProfile: null,
    error: null,
    status: 'notAsked',
    acceptingTermsStatus: 'not',
    acceptingError: null,
  })

  const fetchProfileAndUpdateState = useCallback(async () => {
    setUserProfile((s) => ({
      ...s,
      status: 'loading',
    }))
    const userProfile = user ? user : await fetchUserProfile()
    setUserProfile((s) => ({
      ...s,
      status: 'loaded',
      userProfile,
    }))
    datadogLogs.setUser({
      id: userProfile?.id || '',
    })
  }, [user, setUserProfile])

  useEffect(() => {
    fetchProfileAndUpdateState()
  }, [fetchProfileAndUpdateState])

  const acceptTermsFn = useCallback(
    async (params: AcceptTermsAndConditionsInputV1) => {
      setUserProfile({ ...userProfile, acceptingTermsStatus: 'accepting' })

      await acceptTerms(params)
      const profile = user ? user : await fetchUserProfile()
      setUserProfile({
        ...userProfile,
        userProfile: profile,
        acceptingTermsStatus: 'accepted',
      })
    },
    [setUserProfile, userProfile, user]
  )

  const value = useMemo(
    () => ({
      userProfileState: userProfile,
      refetch: fetchProfileAndUpdateState,
      acceptTerms: acceptTermsFn,
    }),
    [userProfile, fetchProfileAndUpdateState, acceptTermsFn]
  )

  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  )
}

/**
 * Weirdly we have two provider components, but can share a consumer hook; as due to MF they are in effect different contexts
 */
export function UserProfileForwardedProvider({
  value,
  children,
}: {
  value: UserProfileValue
  children: React.ReactNode
}) {
  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  )
}

type UseProtectedProps = {
  permissions?: string[]
  somePermissions?: string[]
  features?: Feature[]
  disabledEnvironments?: string[]
}

export const useProtected = (useProtected: UseProtectedProps): boolean => {
  const {
    userProfileState: { userProfile },
  } = useUserProfile()
  const userPermissions = userProfile?.permissions || []
  const envConfig = getEnvConfig()
  const environment = envConfig?.ENVIRONMENT || ''

  // Transforming the switched on features to an array of strings containing the feature title
  const userEnabledFeatures = getUserEnabledFeatures(userProfile)

  return isAuthorized({
    useProtected,
    userPermissions,
    environment,
    userEnabledFeatures,
  })
}

type ProtectedCallback = (props: UseProtectedProps) => boolean

// Hook that returns a callback so you can keep checking permissions in a loop
export function useProtectedCallback(): ProtectedCallback {
  const {
    userProfileState: { userProfile },
  } = useUserProfile()
  const userPermissions = userProfile?.permissions || []
  const envConfig = getEnvConfig()
  const environment = envConfig?.ENVIRONMENT || ''

  // Transforming the switched on features to an array of strings containing the feature title
  const userEnabledFeatures = getUserEnabledFeatures(userProfile)

  return (useProtected: UseProtectedProps) =>
    isAuthorized({
      useProtected,
      userPermissions,
      environment,
      userEnabledFeatures,
    })
}

type IsAuthorizedProps = {
  useProtected: UseProtectedProps
  userPermissions: string[]
  environment: string
  userEnabledFeatures: string[]
}

export function isAuthorized({
  useProtected: {
    permissions = [],
    somePermissions = [],
    features = [],
    disabledEnvironments = [],
  },
  userPermissions,
  environment,
  userEnabledFeatures,
}: IsAuthorizedProps) {
  // Checking that all the required permissions and features exist in the permissions and features the user actually has
  const pipe = [
    permissions.every((x) => userPermissions.includes(x)),
    somePermissions.length > 0
      ? somePermissions.some((x) => userPermissions.includes(x))
      : true,
    features.every((x) => userEnabledFeatures.includes(x)),
    !disabledEnvironments.includes(environment),
  ]

  return pipe.every(Boolean)
}

export function getUserEnabledFeatures(userProfile: UserProfileV1 | null) {
  return (
    userProfile?.features
      // 'ON' is value of UserProfileFeatureV1Status.ON
      // typescript confusion means we can't use the enum here
      ?.filter(({ status }) => status === 'ON')
      .map(({ name }) => name) || []
  )
}

export const Protected = ({
  children,
  permissions = [],
  somePermissions = [],
  features = [],
  disabledEnvironments = [],
  unauthorizedComponent = null,
}: {
  children: React.ReactNode
  permissions?: string[]
  somePermissions?: string[]
  features?: Feature[]
  unauthorizedComponent?: React.ReactNode
  disabledEnvironments?: string[]
}) => {
  const {
    userProfileState: { status },
  } = useUserProfile()
  const canViewComponent = useProtected({
    permissions,
    somePermissions,
    features,
    disabledEnvironments,
  })

  if (status !== 'loaded') {
    return null
  }
  if (canViewComponent) {
    return children as JSX.Element
  }
  return unauthorizedComponent as JSX.Element
}
